import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import i18n from "i18next";

import { TypeAnimation } from "react-type-animation";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// images
import main01 from "../../assets/images/bg_main01.png";
import coin from "../../assets/images/img_coin.png";
import twitter from "../../assets/images/img_twitter.png";
import instagram from "../../assets/images/img_instagram.png";
import telegram from "../../assets/images/img_telegram.png";
import medium from "../../assets/images/img_medium.png";

// file
import pdfKO from "../../assets/file/GNW_white_paper_240715_KR.pdf";
import pdfEN from "../../assets/file/GNW_WP_ENG_241011.pdf";

// layouts
import Partner from "../layout/Partner";
import Team from "../layout/Team";
import About from "../layout/About";
import Solution from "../layout/Solution";
import News from "../layout/News";

export default function Main() {
  // 다국어 기능
  const { t } = useTranslation();

  useEffect(() => {
    // Main ani - what we do
    let mainTit = document.querySelector("#main .main_bottom .main_title");
    mainTit.innerHTML = mainTit.textContent.replace(/./g, "<span>$&</span>");

    // 페이지 이동 부드럽게
    document.querySelector("html").style.scrollBehavior = "smooth";

    let gnbNavBtn = document.querySelectorAll(
      ".header .gnb_nav_menu_list .gnb_nav_menu_button"
    );
    let mainSection = document.querySelectorAll("section");

    window.addEventListener("scroll", gnbActive);
    window.addEventListener("resize", mobileBg);
    window.addEventListener("load", function () {
      localStorage.setItem("bgIdx", main01);
    });
    mobileBg();

    // 스크롤시 해당 섹션 gnb에 active 추가
    function gnbActive() {
      let scrollTop = window.scrollY;
      let sec00Top = mainSection[0].offsetTop;
      let sec01Top = mainSection[1].offsetTop - 200;
      let sec02Top = mainSection[3].offsetTop - 200;
      let sec03Top = mainSection[5].offsetTop - 200;

      for (let i = 0; i < 6; i++) {
        gnbNavBtn[i].classList.remove("active");

        if (scrollTop >= sec00Top && scrollTop < sec01Top) {
          gnbNavBtn[0].classList.add("active");
        }
        if (scrollTop >= sec01Top && scrollTop < sec02Top) {
          gnbNavBtn[1].classList.add("active");
        }
        if (scrollTop >= sec02Top && scrollTop < sec03Top) {
          gnbNavBtn[2].classList.add("active");
        }
        if (scrollTop >= sec03Top) {
          gnbNavBtn[3].classList.add("active");
        }
      }
    }
    // 모바일 메인 비주얼 위치 조절
    function mobileBg() {
      if (window.innerWidth < 581) {
        document.querySelector(".main_bg").classList.add("mobile");
      } else {
        document.querySelector(".main_bg").classList.remove("mobile");
      }
    }
  });

  /* const TypeComponent = () => {
    return (
      <div className="main_text text03">
        <TypeAnimation
          sequence={[
            1000,
            "GREAT",
            300,
            "GREAT GLAND",
            300,
            "GREAT GLAND GLOBAL",
            300,
            "GREAT GLAND GLOBAL GOOD",
            300,
            "GREAT GLAND GLOBAL GOOD NET WORK",
          ]}
          wrapper="span"
          speed={40}
          className={"main_text text01 font_mont"}
        />
      </div>
    );
  }; */

  return (
    <main className={i18n.language === "ko" ? "cont_wrap" : "cont_wrap en"}>
      {/* main */}
      <section id="main" className="section">
        <div className="main_bg"></div>
        <div className="inner">
          <article className="main_visual">
            <div className="text_wrap">
              <div className="main_text text01 font_mont">
                Institutional-Grade Finance, Now Onchain.
              </div>
              <div className="main_text text02">
                <>
                  <span className="font_mont">
                    <span className="font_mont text_b">GNW</span>
                    <Trans
                      i18nKey="mainText"
                      components={{ 1: <br className="only_mo" />, 2: <br /> }}
                    />
                    <br className="only_mo" />
                  </span>
                </>
              </div>
              <Link
                // to={i18n.language === "ko" ? pdfKO : pdfEN}
                // diabled white paper(KO)
                to={pdfEN}
                target="_blank"
                className="btn_download"
              >
                <span className="font_mont">WHITE PAPER</span>
              </Link>
            </div>
            <div className="link">
              <Link to="https://x.com/GNWteam" target="_blank">
                <img src={twitter} alt="gnw_twitter" />
              </Link>
              <Link to="https://medium.com/@GNW_official" target="_blank">
                <img src={medium} alt="gnw_medium" />
              </Link>
              <Link to="https://t.me/GoodNetWork_t" target="_blank">
                <img src={telegram} alt="gnw_telegram" />
              </Link>
            </div>
          </article>
          <article className="main_bottom">
            <h2 className="main_title" data-aos="fade">
              CEO GREETINGS
            </h2>
            <p
              className="main_desc"
              data-aos="fade-up"
              data-aos-anchor-placement="bottom-bottom"
            >
              <Trans
                i18nKey="whatWeDo"
                components={{ 1: <br className="only_mo" />, 2: <br /> }}
              />
            </p>

            <div className="main_bottom_cont">
              <div className="coin_box">
                <img
                  className="img_coin"
                  src={coin}
                  alt="GNW COIN"
                  data-aos="fade"
                />
                <div className="coin_box_inner">
                  <h1 data-aos="fade">GNW</h1>
                  <p
                    className="font_mont sub_tit"
                    data-aos="fade-up"
                    data-aos-anchor-placement="bottom-bottom"
                  >
                    <Trans
                      i18nKey="whatWeDo2"
                      components={{ 1: <br className="only_mo" />, 2: <br /> }}
                    />
                  </p>
                </div>
              </div>
              <div className="ceo_box">
                <div className="ceo_inner">
                  <h1 data-aos="fade">CEO Message</h1>
                  <p
                    className="font_mont sub_tit"
                    data-aos="fade-up"
                    data-aos-anchor-placement="bottom-bottom"
                  >
                    <Trans
                      i18nKey="ceoMsg"
                      components={{ 1: <br className="only_mo" />, 2: <br /> }}
                    />
                  </p>
                </div>
              </div>
            </div>
          </article>
        </div>
      </section>
      <Team />
      <Partner />
      <About />
      <Solution />
      <News />
    </main>
  );
}
