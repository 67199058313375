import { Link, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import i18n from "i18next";
import pdfEN from "../../assets/file/GNW_WP_ENG_241011.pdf";
import pdfKO from "../../assets/file/GNW_white_paper_240715_KR.pdf";
import Cookies from "universal-cookie";

export default function Header() {
  // 다국어 기능
  function changeKR() {
    i18n.changeLanguage("ko");
  }
  function changeEn() {
    i18n.changeLanguage("en");
  }
  // 네이게이터
  const navigate = useNavigate();

  // 다운로드/언어선택
  const [isDownActive, setDownActive] = useState(false);
  const [isLangActive, setLangActive] = useState(false);

  const DownActive = () => {
    setDownActive(true);
  };
  const DownActiveRemove = () => {
    setDownActive(false);
  };
  const LangActive = () => {
    setLangActive((isLangActive) => !isLangActive);
  };
  const LangActiveRemove = () => {
    setLangActive(false);
  };

  // 사이드메뉴
  const [isOpen, setMenu] = useState(false);
  const toggleMenu = () => {
    setMenu((isOpen) => !isOpen);
    if (isOpen) {
      document.querySelector("header").classList.add("side_open");
    } else {
      document.querySelector("header").classList.remove("side_open");
    }
  };
  const sideMenuClose = () => {
    setMenu(false);
    document.querySelector("header").classList.remove("side_open");
  };

  useEffect(() => {
    let langSelect = document.querySelectorAll(".lang_select li");
    let langBtn = document.querySelector(".gnb_nav_menu_button.lang");
    let gnbBtn = document.querySelectorAll(".btn_gnb");
    let gnbBar = document.querySelector(".gnb_nav_bar");

    window.addEventListener("scroll", moveBar);
    window.addEventListener("resize", () => {
      sideMenuClose();
      moveBar();
    });

    // gnb active bar
    function moveBar() {
      setTimeout(() => {
        gnbBtn.forEach((el) => {
          if (el.classList.contains("active")) {
            let gnbActive = el;
            let gnbActiveWidth = gnbActive.clientWidth;
            let gnbActiveLeft = gnbActive.getBoundingClientRect().left;

            gnbBar.style.width = gnbActiveWidth + "px";
            gnbBar.style.left = gnbActiveLeft + "px";
          }
        });

        if (window.innerWidth <= 1200) {
          gnbBar.style.display = "none";
        } else {
          gnbBar.style.display = "inline-block";
        }
      }, 0);
    }

    // 언어선택
    // 전에 선택한 언어로 세팅
    if (i18n.language === "en") {
      document.querySelector(".gnb_nav_menu_button.lang").innerText = "ENG";
      document
        .querySelector(".header .gnb_nav_menu.lang .select_box .en")
        .classList.add("current");
      document.querySelector("html").setAttribute("lang", "en");
    } else {
      document.querySelector(".gnb_nav_menu_button.lang").innerText = "KOR";
      document
        .querySelector(".header .gnb_nav_menu.lang .select_box .kr")
        .classList.add("current");
      document.querySelector("html").setAttribute("lang", "ko");
    }
    langSelect.forEach((el) => {
      el.addEventListener("click", function () {
        for (let i = 0; i < langSelect.length; i++) {
          langSelect[i].classList.remove("current");
        }
        this.classList.add("current");

        let langBtnActive = document.querySelector(
          ".lang_select li.current button"
        );
        langBtn.innerText = langBtnActive.innerText;
        setLangActive(false);
      });
    });
  }, []);

  const { pathname } = useLocation();
  const checkUrl = pathname.includes("adm");

  return (
    <>
      <header className="header">
        <div className="inner d-flex flex-ac flex-js">
          <h1 className="logo">
            <Link to={checkUrl ? "/adm" : "/"} className="logo_img"></Link>
          </h1>
          <span className="gnb_nav_bar"></span>
          <nav className="gnb_nav">
            <ul className="gnb_nav_menu_list d-flex flex-ac">
              <li className="gnb_nav_menu">
                <a
                  href="#main"
                  className="gnb_nav_menu_button btn_gnb"
                  onClick={() => {
                    sideMenuClose();
                    checkUrl ? navigate("/adm/#main") : navigate("/#main");
                    LangActiveRemove();
                    DownActiveRemove();
                  }}
                >
                  Main
                </a>
              </li>
              <li className="gnb_nav_menu">
                <a
                  href="#team"
                  className="gnb_nav_menu_button btn_gnb"
                  onClick={() => {
                    sideMenuClose();
                    checkUrl ? navigate("/adm/#about") : navigate("/#greeting");
                    LangActiveRemove();
                    DownActiveRemove();
                  }}
                >
                  About GNW
                </a>
              </li>
              <li className="gnb_nav_menu">
                <a
                  href="#about"
                  className="gnb_nav_menu_button btn_gnb"
                  onClick={() => {
                    sideMenuClose();
                    checkUrl ? navigate("/adm/#about") : navigate("/#about");
                    LangActiveRemove();
                    DownActiveRemove();
                  }}
                >
                  GNW Technology
                </a>
              </li>
              <li className="gnb_nav_menu news">
                <a
                  href="#news"
                  className="gnb_nav_menu_button btn_gnb"
                  onClick={() => {
                    sideMenuClose();
                    checkUrl ? navigate("/adm/#news") : navigate("/#news");
                    DownActiveRemove();
                    LangActiveRemove();
                  }}
                >
                  News & Notice
                </a>
              </li>

              <li className="gnb_nav_menu down">
                <Link to={pdfEN} target="_blank" className="btn font_roboto">
                  <button
                    className={
                      isDownActive
                        ? "gnb_nav_menu_button download active"
                        : "gnb_nav_menu_button"
                    }
                    /* onClick={() => {
                    sideMenuClose();
                    DownActive();
                    LangActiveRemove();
                  }} */
                  >
                    White Paper
                  </button>
                  {/*  <ul className="select_box down_select">
                  <li
                    className="kr"
                    onClick={() => {
                      sideMenuClose();
                      DownActiveRemove();
                      LangActiveRemove();
                    }}
                  >
                    <Link
                      to={pdfKO}
                      target="_blank"
                      className="btn font_roboto"
                    >
                      <span>WHITE PAPER(KOR)</span>
                    </Link>
                  </li>
                  <li
                    className="en"
                    onClick={() => {
                      sideMenuClose();
                      DownActiveRemove();
                      LangActiveRemove();
                    }}
                  >
                    <Link
                      to={pdfEN}
                      target="_blank"
                      className="btn font_roboto"
                    >
                      <span>WHITE PAPER(ENG)</span>
                    </Link>
                  </li>
                </ul> */}
                </Link>
              </li>
              <li className="gnb_nav_menu lang">
                <button
                  className={
                    isLangActive
                      ? "gnb_nav_menu_button lang active"
                      : "gnb_nav_menu_button lang"
                  }
                  onClick={() => {
                    DownActiveRemove();
                    LangActive();
                  }}
                >
                  ENG
                </button>
                <ul className="select_box lang_select">
                  <li
                    className="en"
                    onClick={() => {
                      sideMenuClose();
                      changeEn();
                    }}
                  >
                    <button className="btn font_roboto">ENG</button>
                  </li>
                  <li
                    className="kr"
                    onClick={() => {
                      sideMenuClose();
                      changeKR();
                    }}
                  >
                    <button className="btn font_roboto">KOR</button>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
          <button className="ham_menu" onClick={toggleMenu}>
            <span className="ham_menu_bar">
              <span className="bar"></span>
            </span>
          </button>
        </div>
        <div className="side_dim" onClick={sideMenuClose}></div>
      </header>
    </>
  );
}

// scroll header
document.addEventListener("scroll", function () {
  let header = document.querySelector(".header");

  if (window.scrollY > 100) {
    document.querySelector(".btn_scrollTop").classList.add("show");
    header.classList.add("down");
  } else {
    document.querySelector(".btn_scrollTop").classList.remove("show");
    header.classList.remove("down");
  }
});
