import CEOImg from "../../assets/images/ceo_profile.png";
import CSOImg from "../../assets/images/cso_profile.png";
import COOImg from "../../assets/images/coo_profile.png";
import CFOImg from "../../assets/images/cfo_profile.png";
import CCOImg from "../../assets/images/cco_profile.png";
import CBOImg from "../../assets/images/cbo_profile.png";
import teamMember1 from "../../assets/images/team_member1.png";
import teamMember2 from "../../assets/images/team_member2.png";
import teamMember3 from "../../assets/images/team_member3.png";
import teamMember4 from "../../assets/images/team_member4.png";
import teamMember5 from "../../assets/images/team_member5.png";
import teamMember6 from "../../assets/images/team_member6.png";
import teamMember7 from "../../assets/images/team_member7.png";
import tempProfile from "../../assets/images/img_team_coo.png";
import { useTranslation } from "react-i18next";

export default function Team() {
  const { t } = useTranslation();
  return (
    <section id="team" className="section">
      <div className="inner">
        <h2 className="main_title" data-aos="fade">
          Team
        </h2>
        <h2 className="main_title sub" data-aos="fade">
          Institutional-Grade Finance. <br />
          Onchain. For Everyone.
        </h2>
        <p
          className="team_desc"
          data-aos="fade-up"
          data-aos-anchor-placement="bottom-bottom"
        >
          Quality security tokens. Protocols that enhance them.
        </p>
        <p
          className="team_desc"
          data-aos="fade-up"
          data-aos-anchor-placement="bottom-bottom"
        >
          Advisory when you need it. Welcome to the future of finance. Meet our
          team
        </p>
        <div className="cont">
          <div className="profile_wrap d-flex">
            <div
              className="profile d-flex"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="img_box ceo">
                <img src={CEOImg} alt="CEO 일러스트 이미지" />
              </div>
              <div className="info_box">
                <p className="name_rank">CEO</p>
                <p className="name_en font_mont">Joo Won Kim</p>
                <div className="text_box">
                  <p>- GNW Chief Executive Officer</p>
                  <p>- Wizclass Co. Ltd. CEO</p>
                  <p>- Technical Director of Korea Blockchain Association</p>
                  <p>- Korea Information & Communications Co. Ltd. CEO</p>
                  <p>- WILLSOFT CEO</p>
                </div>
              </div>
            </div>
            <div
              className="profile d-flex"
              data-aos="fade-right"
              data-aos-delay="200"
              data-aos-duration="1000"
            >
              <div className="img_box">
                <img src={CSOImg} alt="CSO 일러스트 이미지" />
              </div>
              <div className="info_box">
                <p className="name_rank">CSO</p>
                <p className="name_en font_mont">Andy Song</p>
                <div className="text_box">
                  <p>- GNW Chief Strategy Officer</p>
                  <p>- Onest invest CEO</p>
                  <p>- Linkercoin ex co-founder</p>
                  <p>- Etc network former CEO</p>
                  <p>- Korean NFT content association director</p>
                </div>
              </div>
            </div>
          </div>
          <div className="profile_wrap d-flex">
            <div
              className="profile d-flex"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="img_box reverse teamLeader leader01">
                <img src={CBOImg} alt="CBO 일러스트 이미지" />
              </div>
              <div className="info_box">
                <p className="name_rank">CBO</p>
                <p className="name_en font_mont">Park</p>
                <div className="text_box">
                  <p>- GNW Chief Business Officer</p>
                  <p>- Rolling Stone Korea Co. Ltd. Chairman</p>
                  <p>- Elephant ENT Co. Ltd. CEO</p>
                  <p>- P&C Platform Co. Ltd. CEO</p>
                  <p>
                    - Advisory Committee Chairman, National Student Councils
                    Union Youth Forum
                  </p>
                </div>
              </div>
            </div>
            <div
              className="profile d-flex"
              data-aos="fade-left"
              data-aos-delay="200"
              data-aos-duration="1000"
            >
              <div className="img_box reverse teamLeader leader02">
                <img src={COOImg} alt="COO 일러스트 이미지" />
              </div>
              <div className="info_box">
                <p className="name_rank">COO</p>
                <p className="name_en font_mont">Hwang</p>
                <div className="text_box">
                  <p>- GNW Chief Operating Officer</p>
                  <p>- Wizclass Co. Ltd. Director</p>
                  <p>- HANAM GYOSAN CEO</p>
                  <p>- INKANG CONSTRUCTION Vice President </p>
                </div>
              </div>
            </div>
          </div>
          <div className="profile_wrap d-flex">
            <div
              className="profile d-flex"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="img_box ceo">
                <img src={CFOImg} alt="CFO 일러스트 이미지" />
              </div>
              <div className="info_box">
                <p className="name_rank">CFO</p>
                <p className="name_en font_mont">Lee</p>
                <div className="text_box">
                  <p>- GNW Chief Financial Officer</p>
                  <p>- TSTEM CEO</p>
                  <p>- Wizclass Co. Ltd. vice chairman</p>
                  <p>
                    - CLUBHOUSE PachinKo Chairman and Nakdonggang Mining Right
                    CEO
                  </p>
                </div>
              </div>
            </div>
            <div
              className="profile d-flex"
              data-aos="fade-right"
              data-aos-delay="200"
              data-aos-duration="1000"
            >
              <div className="img_box">
                <img src={CCOImg} alt="CCO 일러스트 이미지" />
              </div>
              <div className="info_box">
                <p className="name_rank">CCO</p>
                <p className="name_en font_mont">Jeong</p>
                <div className="text_box">
                  <p>- GNW Chief Committee Officer</p>
                  <p>- Leeand Invest Inc. Vice President</p>
                  <p>- Tium Investment partners Co. Ltd. CEO</p>
                  <p>- MO Ventures Co. Ltd. Managing Director</p>
                </div>
              </div>
            </div>
          </div>

          <div className="profile_wrap d-flex">
            <div
              className="profile d-flex"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="img_box reverse teamLeader leader01">
                <img src={teamMember5} alt="Advisor 일러스트 이미지" />
              </div>
              <div className="info_box">
                <p className="name_rank">Advisor</p>
                <p className="name_en font_mont">Lee Seung-mok</p>
                <div className="text_box">
                  <p>
                    - Chairman, non-profit organization Foundation Management
                    Forum
                  </p>
                  <p>- Chairman of Consumer Journal</p>
                  <p>- Chairman, Consumer Journal Cooperative</p>
                  <p>
                    - 2004-2008 NHN Co., Ltd., General Manager of Naver Law/Tax
                    Management
                  </p>
                  <p>
                    - 2000-2001 General Manager of Hyundai Information
                    Technology Project
                  </p>
                  <p>
                    - 1998-1999 General Manager of Korea Telecom Connet World
                  </p>
                </div>
              </div>
            </div>
            <div
              className="profile d-flex"
              data-aos="fade-left"
              data-aos-delay="200"
              data-aos-duration="1000"
            >
              <div className="img_box reverse teamLeader leader02">
                <img src={teamMember7} alt="Advisor 일러스트 이미지" />
              </div>
              <div className="info_box">
                <p className="name_rank">Advisor</p>
                <p className="name_en font_mont">Baek Seung-gwang</p>
                <div className="text_box">
                  <p>- GNW Advisor</p>
                  <p>- CEO of Blockwice Ratings</p>
                  <p>- NICE Group Strategic Planning Team Leader</p>
                  <p>
                    - Master of Economics in Law History at Yonsei University
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="profile_wrap d-flex">
            <div
              className="profile d-flex"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="img_box">
                <img src={teamMember1} alt="Advisor 일러스트 이미지" />
              </div>
              <div className="info_box">
                <p className="name_rank">Advisor</p>
                <p className="name_en font_mont">Charles</p>
                <div className="text_box">
                  <p>- GNW Advisor</p>
                  <p>- Wizclass Co. Ltd. Executive Manager</p>
                  <p>- Crypto Fund Department Head</p>
                  <p>- Sky Leisure Sports CEO</p>
                  <p>- World Bungee Jumping Association Master Certification</p>
                </div>
              </div>
            </div>
            <div
              className="profile d-flex"
              data-aos="fade-right"
              data-aos-delay="200"
              data-aos-duration="1000"
            >
              <div className="img_box">
                <img src={teamMember2} alt="CTO 일러스트 이미지" />
              </div>
              <div className="info_box">
                <p className="name_rank">Advisor</p>
                <p className="name_en font_mont">Kelsry</p>
                <div className="text_box">
                  <p>- GNW Advisor</p>
                  <p>- Wizclass Co. Ltd. Manager</p>
                  <p>- Google Ads Certification Master</p>
                  <p>- Overture Korea Keyword advertising manager</p>
                  <p>- Daum Online Advertising Manager</p>
                  <p>- Nate On Online Advertising Manager</p>
                </div>
              </div>
            </div>
          </div>

          <div className="profile_wrap d-flex">
            <div
              className="profile d-flex"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="img_box reverse teamLeader leader01">
                <img src={teamMember3} alt="SE 일러스트 이미지" />
              </div>
              <div className="info_box">
                <p className="name_rank">CTO</p>
                <p className="name_en font_mont">Artanis</p>
                <div className="text_box">
                  <p>- GNW Chief Technical Officer</p>
                  <p>- Wizclass Co. Ltd. Manager</p>
                  <p>- Numerous use cases of Blockchain Technology</p>
                  <p>- Coin issuance and staking solutions</p>
                  <p>- Development of Multiple stock auto trading solutions</p>
                  <p>
                    - Integration of Multiple Securities Firms such as Kiwoom,
                    E*TRADE, and Yuanta for Automated Trading and AI Analysis
                  </p>
                </div>
              </div>
            </div>
            <div
              className="profile d-flex"
              data-aos="fade-left"
              data-aos-delay="200"
              data-aos-duration="1000"
            >
              <div className="img_box reverse teamLeader leader02">
                <img src={teamMember4} alt="SE 일러스트 이미지" />
              </div>
              <div className="info_box">
                <p className="name_rank">Senior Engineer</p>
                <p className="name_en font_mont">Canoa</p>
                <div className="text_box">
                  <p>- GNW Senior Engineer</p>
                  <p>- WIZCLASS Co. Ltd. Senior Engineer</p>
                  <p>- Fox Eletronic Co. Ltd. Senior Engineer</p>
                  <p>- Development of staking solutions</p>
                  <p>- Development of dex solutions</p>
                </div>
              </div>
            </div>
          </div>
          <div className="profile_wrap d-flex">
            <div
              className="profile d-flex"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <div className="img_box">
                <img src={teamMember6} alt="Advisor 일러스트 이미지" />
              </div>
              <div className="info_box">
                <p className="name_rank">Senior Engineer</p>
                <p className="name_en font_mont">RINA</p>
                <div className="text_box">
                  <p>- GNW Senior Engineer</p>
                  <p>- WIZCLASS Co. Ltd. Senior Engineer</p>
                  <p>- ERP/MES Team Leader</p>
                  <p>- Goods Shopping Mall Senior Engineer</p>
                  <p>
                    - News and Survey Platform Development Team Senior Engineer
                  </p>
                </div>
              </div>
            </div>
            {/* <div
              className="profile d-flex"
              data-aos="fade-right"
              data-aos-delay="200"
              data-aos-duration="1000"
            >
              <div className="img_box">
                <img src={teamMember3} alt="CTO 일러스트 이미지" />
              </div>
              <div className="info_box">
                <p className="name_rank">Advisor</p>
                <p className="name_en font_mont">Kelsry</p>
                <div className="text_box">
                  <p>- GNW Advisor</p>
                  <p>- Wizclass Co. Ltd. Manager</p>
                  <p>- Google Ads Certification Master</p>
                  <p>- Overture Korea Keyword advertising manager</p>
                  <p>- Daum Online Advertising Manager</p>
                  <p>- Nate On Online Advertising Manager</p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}
